import { type Theme, type TypographyVariantsOptions } from '@mui/material/styles';
import '@fontsource/source-sans-pro';
import '@fontsource/source-sans-pro/200.css';
import '@fontsource/source-sans-pro/300.css';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/600.css';
import '@fontsource/source-sans-pro/700.css';
import '@fontsource/source-sans-pro/900.css';

const Typography = (theme: Theme): TypographyVariantsOptions => ({
  htmlFontSize: 10,
  allVariants: {
    fontFamily: 'Source Sans Pro, Sans-serif',
    color: theme.palette.text.primary,
    fontSize: '1.6rem',
    letterSpacing: '0.75px',
    fontWeight: 400,
    WebkitFontSmoothing: 'antialiased',
    lineHeight: '2rem',
  },
  h1: {
    fontSize: '10rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '2.2rem',
    lineHeight: '12rem',
  },
  h3: {
    fontSize: '5rem',
    fontWeight: 700,
    lineHeight: '4.2rem',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  h4: {
    fontSize: '3rem',
    fontWeight: 700,
    lineHeight: '3.8rem',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  h5: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.2rem',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  h6: {
    fontSize: '1.6rem',
    fontWeight: 700,
    lineHeight: '1.92rem',
    letterSpacing: '.8px',
  },
});

export default Typography;
