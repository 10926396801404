import Link from './Link';
import TextField from './TextField';
import Toolbar from './Toolbar';
import Typography from './Typography';
import { type Components, type Theme } from '@mui/material/styles';
import { merge } from 'lodash';

const ComponentsOverrides = (theme: Theme): Components<any> | undefined => {
  return merge(Typography(theme), Link(theme), TextField(theme), Toolbar(theme));
};

export default ComponentsOverrides;
