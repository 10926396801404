import * as Sentry from '@sentry/react';

export const initSentry = (): void => {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: import.meta.env.DEV ? 1.0 : 0.1,
    sampleRate: import.meta.env.VITE_APP_LOCAL ? 0 : 1.0,
  });
};
