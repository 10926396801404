import MainLayout from '@Components/layouts/MainLayout';
import Loadable from '@Components/Loadable';
import { lazy } from 'react';

const Home = Loadable(lazy(async () => await import('@Pages/Home')));
const Contact = Loadable(lazy(async () => await import('@Pages/Contact')));

const MainRoutes = {
  children: [
    {
      element: <Home />,
      path: '',
    },
    {
      element: null,
      path: 'labor',
    },
    {
      element: null,
      path: 'ueber-uns',
    },
    {
      element: <Contact />,
      path: 'individuelle-anfrage',
    },
  ],
  element: <MainLayout />,
  path: '',
};

export default MainRoutes;
