import ScrollTop from '@Components/ScrollTop';
import { Box } from '@mui/material';
import Routes from 'routes';

function App(): JSX.Element {
  return (
    <ScrollTop>
      <Box data-testid="app-layout-root">
        <Routes />
      </Box>
    </ScrollTop>
  );
}

export default App;
