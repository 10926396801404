import { type ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollTop = ({ children }: { children: ReactElement }): JSX.Element => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
  }, [pathname]);

  return children;
};

export default ScrollTop;
