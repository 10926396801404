import { type Components, type Theme } from '@mui/material';

const Toolbar = (theme: Theme): { MuiToolbar: Components<Theme>['MuiToolbar'] } => {
  return {
    MuiToolbar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('lg')]: {
            padding: '1rem 0',
          },
          padding: '0 1.5rem',
        },
      },
    },
  };
};

export default Toolbar;
