import componentsOverride from './overrides';
import Palette from './palette';
import Typography from './typography';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import {
  type Theme,
  type ThemeOptions,
  ThemeProvider,
  type TypographyVariantsOptions,
  createTheme,
} from '@mui/material/styles';
import { type ReactNode, useMemo } from 'react';

// types
interface ThemeCustomizationProps {
  children: ReactNode;
}

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

const ThemeCustomization = ({ children }: ThemeCustomizationProps): JSX.Element => {
  const theme: Theme = useMemo<Theme>(() => Palette(), []);

  const themeTypography: TypographyVariantsOptions = useMemo<TypographyVariantsOptions>(
    () => Typography(theme),
    [theme],
  );

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          lg: 1024,
          md: 768,
          sm: 576,
          xl: 1140,
          xs: 0,
        },
      },
      palette: theme.palette,
      typography: themeTypography,
    }),
    [theme, themeTypography],
  );

  const themes: Theme = createTheme(themeOptions);
  themes.components = componentsOverride(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeCustomization;
