import { Box, Skeleton, useTheme } from '@mui/material';

const PageSkeleton = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
      <Box sx={{ backgroundColor: theme.palette.grey[200], height: '10rem' }}>
        <Skeleton
          sx={{ height: '100%' }}
          animation="wave"
          variant="rectangular"
        />
      </Box>
      <Skeleton
        sx={{ height: 'calc(100% - 20rem)' }}
        animation="wave"
        variant="rectangular"
      />
      <Box sx={{ backgroundColor: theme.palette.grey[200], height: '10rem' }}>
        <Skeleton
          sx={{ height: '100%' }}
          animation="wave"
          variant="rectangular"
        />
      </Box>
    </Box>
  );
};

export default PageSkeleton;
