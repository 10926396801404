import { Box, Skeleton, useTheme } from '@mui/material';
import { useMemo } from 'react';

const ContentSkeleton = (): JSX.Element => {
  const theme = useTheme();

  const skeletons = useMemo(
    () => (
      <>
        {[...new Array(20)].map((_, i) => (
          <Skeleton
            key={`skeleton-${i}`}
            animation="wave"
            sx={{ marginBottom: '1rem' }}
            height={'1rem'}
            width={`${Math.random() * 100}%`}
          />
        ))}
      </>
    ),
    [],
  );

  return (
    <Box
      sx={{
        height: '100rem',
        width: '100%',
      }}>
      <Box sx={{ backgroundColor: theme.palette.grey[200], height: { xs: '35rem', lg: '50rem' } }}>
        <Skeleton
          sx={{ height: '100%' }}
          animation="wave"
          variant="rectangular"
        />
      </Box>
      <Box
        sx={{
          height: 'calc(100% - 50rem)',
          maxWidth: theme.breakpoints.values.xl,
          margin: 'auto',
          padding: '5rem 2rem',
        }}>
        {skeletons}
      </Box>
    </Box>
  );
};

export default ContentSkeleton;
