import ContentSkeleton from '@Components/skeleton/ContentSkeleton/ContentSkeleton';
import { type ElementType, Suspense } from 'react';

// eslint-disable-next-line react/display-name
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<ContentSkeleton />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
