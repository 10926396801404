import ContentSkeleton from '@Components/skeleton/ContentSkeleton/ContentSkeleton';
import PageSkeleton from '@Components/skeleton/PageSkeleton/PageSkeleton';
import { Box, Container } from '@mui/material';
import { styled, type Theme, useTheme } from '@mui/material/styles';
import { type RootState } from '@Store';
import { useMobile } from '@Utils/hooks/themeHooks';
import { Suspense, lazy, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { openMenu } from 'store/ui/uiSlice';

const Header = lazy(async () => await import('./Header'));
const Footer = lazy(async () => await import('./Footer'));

// components content
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'theme' })(
  ({ theme, open }: { open: boolean; theme: Theme }) => ({
    flexGrow: 1,
    minHeight: `calc(100vh - 18rem)`,
    transition: theme.transitions.create('margin', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
    },
    ...(open && {
      transition: theme.transitions.create('margin', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeOut,
      }),
    }),
  }),
);

const MainLayout = (): JSX.Element => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const menuOpen = useSelector((state: RootState) => state.ui.menuOpen);
  const { headerHeight } = useMobile();
  const location = useLocation();
  const { hash } = location;

  const handleMenuOpen = useCallback(
    (open: boolean): void => {
      dispatch(openMenu({ menuOpen: open }));
    },
    [dispatch],
  );

  useEffect(() => {
    handleMenuOpen(false);
  }, [handleMenuOpen, hash]);

  return (
    <Suspense fallback={<PageSkeleton />}>
      <Container
        disableGutters
        maxWidth={false}>
        <Header
          sx={{ height: headerHeight }}
          menuOpen={menuOpen}
          handleMenuOpen={handleMenuOpen}
        />
        <Box
          sx={{
            display: 'flex',
            pt: menuOpen ? { lg: 3, xl: 5.5, xs: 0 } : 0,
            marginTop: headerHeight,
            justifyContent: 'center',
          }}>
          <Main
            theme={theme}
            open={menuOpen}>
            <Suspense fallback={<ContentSkeleton />}>
              <Outlet />
            </Suspense>
          </Main>
        </Box>
        <Footer />
      </Container>
    </Suspense>
  );
};

export default MainLayout;
