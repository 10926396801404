import { type Components, type Theme } from '@mui/material';

const Typography = (theme: Theme): { MuiTypography: Components<Theme>['MuiTypography'] } => {
  return {
    MuiTypography: {
      styleOverrides: {},
    },
  };
};

export default Typography;
