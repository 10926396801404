import { type Language } from 'models/config';
import { type ReactNode, useEffect, useState } from 'react';
import { IntlProvider, type MessageFormatElement } from 'react-intl';

const loadLocaleData = async (locale: Language): Promise<any> => {
  switch (locale) {
    case 'de':
      return await import('utils/locales/de.json');
    default:
      return await import('utils/locales/de.json');
  }
};

interface Props {
  children: ReactNode;
}

const Locales = ({ children }: Props): JSX.Element => {
  const lng: Language = 'de';

  const [messages, setMessages] = useState<
    Record<string, string> | Record<string, MessageFormatElement[]> | undefined
  >();

  useEffect(() => {
    loadLocaleData(lng)
      .then(
        (d: {
          default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined;
        }) => {
          setMessages(d.default);
        },
      )
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [lng]);

  return (
    <>
      {messages != null && (
        <IntlProvider
          locale={lng}
          defaultLocale="de"
          messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};

export default Locales;
