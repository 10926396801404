import {
  type Components,
  type Theme,
  formLabelClasses,
  outlinedInputClasses,
  inputLabelClasses,
} from '@mui/material';

const TextField = (theme: Theme): { MuiTextField: Components<Theme>['MuiTextField'] } => {
  return {
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.common.white,

          [`& .${inputLabelClasses.shrink}`]: {
            transform: 'translate(1.4rem, -0.9rem) scale(0.75) !important',
          },

          [`& .${outlinedInputClasses.input}`]: {
            padding: '0.5rem 1rem',
            minHeight: '3rem',
          },

          [`& .${outlinedInputClasses.root}`]: {
            padding: '0',
          },

          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.primary.main,
            borderWidth: '1px',
            borderRadius: 0,
            fontSize: '1.6rem',
            fontWeight: 700,
            letterSpacing: '.8px',
          },

          [`& .${formLabelClasses.root}`]: {
            fontSize: '1.6rem',
            fontWeight: 700,
            letterSpacing: '.8px',
            opacity: 0.6,
            transform: 'translate(1.4rem, 0.9rem) scale(1)',
          },
        },
      },
    },
  };
};

export default TextField;
