import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface UiState {
  count: number;
  inDarkMode: boolean;
  menuOpen: boolean;
}

export const initialState: UiState = {
  count: 0,
  inDarkMode: false,
  menuOpen: false,
};

export const uiSliceName = 'ui';

export const uiSlice = createSlice({
  initialState,
  name: uiSliceName,
  reducers: {
    toggleDarkMode: (state) => {
      state.inDarkMode = !state.inDarkMode;
    },
    openMenu: (state, action: PayloadAction<{ menuOpen: boolean }>) => {
      state.menuOpen = action.payload.menuOpen;
    },
    incrementCount: (state) => {
      state.count = state.count + 1;
    },
  },
});

// Actions
export const { toggleDarkMode, incrementCount, openMenu } = uiSlice.actions;

// Selectors
export const selectCount = (state: UiState): number => state.count;
export const selectInDarkMode = (state: UiState): boolean => state.inDarkMode;
export const selectMenuOpen = (state: UiState): boolean => state.menuOpen;

export default uiSlice.reducer;
