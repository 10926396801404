import App from '@Components/App';
import Locales from '@Components/Locales';
import { setupStore } from '@Store';
import { initSentry } from '@Utils/config';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxContextProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import ThemeCustomization from 'themes';

const store = setupStore();

initSentry();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ReduxContextProvider store={store}>
      <BrowserRouter>
        <ThemeCustomization>
          <Locales>
            <App />
          </Locales>
        </ThemeCustomization>
      </BrowserRouter>
    </ReduxContextProvider>
  </React.StrictMode>,
);
