import { type Components, type Theme } from '@mui/material';

const Link = (theme: Theme): { MuiLink: Components<Theme>['MuiLink'] } => {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          lineHeight: '1.28rem',
          fontSize: '1.6rem',
          fontWeight: 700,
          textTransform: 'uppercase',
          padding: '1rem 0',
          letterSpacing: '.8px',
        },
      },
    },
  };
};

export default Link;
