import { type Theme, createTheme } from '@mui/material/styles';

const Palette = (): Theme => {
  return createTheme({
    palette: {
      primary: {
        main: '#0b3a27',
      },
      text: {
        primary: '#0b3a27',
      },
      background: {
        default: '#fff',
      },
      common: {
        green: '#becdb0',
        black: '#000',
        white: '#fff',
        lightGray: '#7a7a7a',
        darkGray: '#333333',
        lightGreen: '#becdb0',
      },
    },
  });
};

export default Palette;
