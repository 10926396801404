import uiReducer, { uiSliceName } from './ui/uiSlice';
import {
  type PreloadedState,
  combineReducers,
  configureStore,
  type StoreEnhancer,
  type DevToolsEnhancerOptions,
} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

export const rootReducer = combineReducers({
  [uiSliceName]: uiReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function setupStore(preloadedState?: PreloadedState<RootState>) {
  let additionalEnhancer: StoreEnhancer[] = [];
  let devTools: Partial<DevToolsEnhancerOptions> | boolean = false;
  if (import.meta.env.DEV) {
    additionalEnhancer = [Sentry.createReduxEnhancer()];
    devTools = { maxAge: 50 };
  }
  return configureStore({
    devTools,
    enhancers: [...additionalEnhancer],
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
    preloadedState,
    reducer: rootReducer,
  });
}

// Types
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
