import { type JSXElementConstructor, type ReactElement } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import MainRoutes from './MainRoutes';

const Routes = (): ReactElement<any, string | JSXElementConstructor<any>> | null => {
  return useRoutes([
    MainRoutes,
    {
      element: (
        <Navigate
          to="/"
          replace={true}
        />
      ),
      path: '*',
    },
  ]);
};

export default Routes;
